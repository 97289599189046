import countriesDe from '../JSON/CountriesList/countries-de.json';
import countriesEn from '../JSON/CountriesList/countries-en.json';
import countriesFr from '../JSON/CountriesList/countries-fr.json';
import countriesIt from '../JSON/CountriesList/countries-it.json';
import countriesNl from '../JSON/CountriesList/countries-nl.json';
import countriesEs from '../JSON/CountriesList/countries-es.json';
import {
    DE,
    DE_AT,
    EN,
    EN_AU,
    EN_IN,
    EN_SG,
    EN_UK,
    EN_US,
    ES,
    ES_ES,
    ES_US,
    FR,
    FR_BE,
    FR_FR,
    IT,
    NL,
    NL_BE,
    NL_NL,
    ZH_HANS,
} from '../constants/languageCode';

export const getCountriesInCurrentLanguage = (language: string) => {
    switch (language) {
        case DE:
            return countriesDe;
        case EN:
            return countriesEn;
        case DE_AT:
            return countriesDe;
        case EN_AU:
            return countriesEn;
        case EN_IN:
            return countriesEn;
        case EN_SG:
            return countriesEn;
        case EN_UK:
            return countriesEn;
        case EN_US:
            return countriesEn;
        case ES:
            return countriesEs;
        case ES_ES:
            return countriesEs;
        case ES_US:
            return countriesEs;
        case FR:
            return countriesFr;
        case FR_BE:
            return countriesFr;
        case FR_FR:
            return countriesFr;
        case IT:
            return countriesIt;
        case NL:
        case NL_NL:
        case NL_BE:
            return countriesNl;
        case ZH_HANS:
            return countriesEn;
        default:
            return countriesDe;
    }
};
