import styled from 'styled-components';

export const SelectWithSearchContainer = styled.div`
    position: relative;
`;

export const InputSelector = styled.input`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 0;
    opacity: 0;
    user-select: none;
`;
