import React, { useEffect, FC, useState, useRef, ChangeEvent, ReactNode } from 'react';
import {
    SelectWithSearchInput,
    SelectOptionAndInputWrapper,
    SelectOptionWrapper,
    SelectOption,
    SelectSearchInput,
    SelectOptionError,
} from '../../../styles/Common';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { InputSelector, SelectWithSearchContainer } from './SelectWithSearchStyle';

interface SelectDataInterface {
    name: string;
    value: string;
}

interface SelectWithSearchInterface {
    currentValue: string;
    setState: Function;
    selectData: Array<SelectDataInterface>;
    defaultValue: string;
    width?: string;
    error?: boolean;
    isRequired?: boolean;
    requiredErrorMessage?: string;
}

const SelectWithSearch: FC<SelectWithSearchInterface> = ({
    currentValue,
    setState,
    selectData,
    defaultValue,
    width,
    error,
    isRequired,
    requiredErrorMessage,
}) => {
    const [isSelectActive, setIsSelectActive] = useState(false);
    const [currentSearch, setCurrentSearch] = useState('');
    const [areResults, setAreResults] = useState(false);
    const [selectOptionWidth, setSelectOptionWidth] = useState(width);
    const [selected, setSelected] = useState('');
    const { t } = useTranslation();
    const selectRef = useRef(null);
    const selectSearchRef = useRef(null);
    const inputRef = useRef(null);

    const clickOutsideSelect = (e: any): void => {
        const { current } = selectRef;
        if (current && !current.contains(e.target)) {
            setIsSelectActive(false);
        }
    };

    useEffect(() => {
        const changeOptionWidth = () => {
            if (selectSearchRef && selectSearchRef.current?.clientWidth) {
                let border = getComputedStyle(selectSearchRef.current);
                setSelectOptionWidth(
                    `${
                        selectSearchRef.current.clientWidth +
                        parseInt(border.borderRightWidth) +
                        parseInt(border.borderLeftWidth)
                    }px`
                );
            }
        };
        changeOptionWidth();
        if (typeof document !== 'undefined') {
            document.addEventListener('mousedown', clickOutsideSelect, false);
        }
        if (typeof window !== 'undefined') {
            window.addEventListener('resize', changeOptionWidth);
        }
        return () => {
            document?.removeEventListener('mousedown', clickOutsideSelect, false);
            window?.removeEventListener('resize', changeOptionWidth);
        };
    }, []);

    useEffect(() => {
        setAreResults(false);
    }, [currentSearch]);

    useEffect(() => {
        selected && inputRef?.current?.setCustomValidity('');
    }, [selected]);

    useEffect(() => {
        const getValueByName = (arr: any, value: string): string => arr.find((x: any) => x.name === value)?.value;
        setSelected(getValueByName(selectData, currentValue));
    }, [currentValue]);

    const displaySelectOptions = (): ReactNode =>
        selectData
            .filter((option: SelectDataInterface) => {
                if (currentSearch === '') {
                    return option;
                } else if (option.name.toLocaleLowerCase().includes(currentSearch.toLocaleLowerCase())) {
                    return option;
                } else {
                    return false;
                }
            })
            .map((option: SelectDataInterface) => {
                !areResults && setAreResults(true);
                return displaySelectOption(option);
            });

    const displaySelectOption = (option: SelectDataInterface): ReactNode => (
        <SelectOption
            key={option.name}
            onClick={() => {
                setState(option.name);
                setIsSelectActive(false);
                setCurrentSearch('');
            }}
        >
            {option.value}
        </SelectOption>
    );

    return (
        <SelectWithSearchContainer ref={selectRef}>
            <InputSelector
                ref={inputRef}
                required={!selected && isRequired}
                onInvalid={(e: ChangeEvent<HTMLInputElement>) =>
                    requiredErrorMessage && e.target.setCustomValidity(requiredErrorMessage)
                }
                onChange={() => {}}
                value={selected ?? ''}
            ></InputSelector>
            <SelectWithSearchInput
                isActive={isSelectActive}
                onClick={() => setIsSelectActive((isSelectActive) => !isSelectActive)}
                width={width}
                ref={selectSearchRef}
                error={error}
            >
                {currentValue.length > 0 ? selected : defaultValue}
            </SelectWithSearchInput>
            <SelectOptionAndInputWrapper isActive={isSelectActive} width={selectOptionWidth}>
                <SelectSearchInput
                    type="text"
                    value={currentSearch}
                    onChange={(e) => setCurrentSearch(e.target.value)}
                />
                <SelectOptionWrapper>
                    {currentSearch.length === 0 && (
                        <SelectOption
                            onClick={() => {
                                setState('');
                                setIsSelectActive(false);
                                setCurrentSearch('');
                            }}
                        >
                            {defaultValue}
                        </SelectOption>
                    )}
                    {displaySelectOptions()}
                    {!areResults && (
                        <SelectOptionError>
                            {t('noResultsMatch')} {`'${currentSearch}'`}
                        </SelectOptionError>
                    )}
                </SelectOptionWrapper>
            </SelectOptionAndInputWrapper>
        </SelectWithSearchContainer>
    );
};

export default SelectWithSearch;
