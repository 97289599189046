import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { CommonCheckboxWrapper, CommonCheckboxCheckmark, CommonCheckBox } from '../../../styles/Common';

interface CheckboxInputInterface {
    setState: Function;
    state: any;
    name?: string;
    value?: string;
    required?: boolean;
    requiredErrorMessage?: string;
}

const CheckboxInput: FC<CheckboxInputInterface> = ({
    setState,
    state,
    name,
    value,
    required = false,
    requiredErrorMessage,
}) => {
    const [isActive, setIsActive] = useState(false);

    return (
        <CommonCheckboxWrapper
            onClick={() => {
                setIsActive((isActive) => !isActive);

                setState((state: any) => ({
                    ...state,
                    [name]: [value].concat(state[name]).filter((item) => item !== undefined),
                }));
                if (state.hasOwnProperty(name) && state[name].indexOf(value) !== -1) {
                    setState((state: any) => ({
                        ...state,
                        [name]: state[name]?.filter((item) => item !== value && item !== undefined),
                    }));
                }
            }}
        >
            <CommonCheckBox
                type="checkbox"
                checked={isActive}
                value={value}
                required={required}
                onInvalid={(e: ChangeEvent<HTMLInputElement>) =>
                    requiredErrorMessage && e.target.setCustomValidity(requiredErrorMessage)
                }
                onChange={(e: ChangeEvent<HTMLInputElement>) => e.target.setCustomValidity('')}
            />
            <CommonCheckboxCheckmark isActive={isActive} />
        </CommonCheckboxWrapper>
    );
};

export default CheckboxInput;
