import type { FC, ChangeEvent, ReactNode, CSSProperties } from 'react';

import React, { useState, useEffect } from 'react';
import { useFetchWebform } from '../../hooks/useFetchWebform';
import { useHandleSubmitWebform } from '../../hooks/useHandleSubmitWebform';
import {
    FormSectionHeadline,
    FormSectionTitle,
    FormSectionWrapper,
    RadioBoxContainer,
    RadioLabel,
    Text,
    TextInput,
    InputSectionHeadline,
    InputSectionWrapper,
    Description,
} from '../ContactUk/ContactUkStyle';
import { FileInputWrapper, TextAreaWrapper } from './OnlineApplication/OnlineApplicationStyle';
import { CommonSelect, SendButtonGreenGradient } from '../../styles/Common';
import TextAreaWithGrippie from '../Inputs/TextAreaWithGrippie/TextAreaWithGrippie';
import RadioInput from '../Inputs/RadioInput/RadioInput';
import CheckboxInput from '../Inputs/CheckboxInput/CheckboxInput';
import SelectWithSearch from '../../components/Inputs/SelectWithSearch/SelectWithSearch';
import { getCountriesInCurrentLanguage } from '../../utils/getCountriesInCurrentLanguage';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import {
    LAND,
    CHECKBOXES,
    INPUT,
    CHECKBOX,
    SELECT,
    REQUIRED,
    TITLE,
    WEBFORM_DOCUMENT_FILE,
    REQUIRED_ERROR,
    OPTIONS,
    DESCRIPTION,
    TYPE,
    MULTIPLE,
    PLACEHOLDER,
    FIELDSET,
    TEXTFIELD,
    TEXTAREA,
    EMAIL,
    RADIOS,
    TITLE_DISPLAY,
    DESCRIPTION_DISPLAY,
    TEXT,
    TELEPHONE,
    COUNTRY_NAMES,
    DATELIST,
    DATE_PART_ORDER,
    OPTIONS_DISPLAY,
    DAY,
    MONTH,
    YEAR,
    DATE_YEAR_RANGE,
    TITLE_TYPE,
} from '../../constants/commonConstants';
import Loader from '../Loader/Loader';
import FileInput from '../Inputs/FileInput/FileInput';
import { EN, EN_US } from '../../constants/languageCode';
import { CommonSelectMargin, RadioBoxContainerMargin, RadioContainer, StyledWebform } from './WebFormStyle';
import { getMonthInCurrentLanguage } from '../../utils/getMonthInCurrentLanguage';
import { createDaysObject } from '../../utils/createDaysObject';
import { greyBackground } from '../../styles/Variables';

interface WebFormInterface {
    language: string;
    webformid: string;
    deleteMarginBottom?: boolean;
    confirmationTitle?: string;
    isParagraphType?: boolean;
    style?: CSSProperties;
}

interface WebformObjectInterface {
    readonly schema?: Array<any>;
    readonly wid?: string;
    readonly submission_link?: string;
    readonly link?: string;
    readonly settings?: any;
}

const WebForm: FC<WebFormInterface> = ({
    language,
    webformid,
    deleteMarginBottom,
    confirmationTitle,
    isParagraphType,
    style,
}) => {
    const { t } = useTranslation();
    const [webform, setWebform] = useState<WebformObjectInterface>({});
    const [radios, setRadios] = useState({});
    const [checkboxes, setCheckboxes] = useState({});
    const [requiredRadios, setRequiredRadios] = useState([]);
    const [requiredCheckboxes, setRequiredCheckboxes] = useState([]);
    const [requiredSelects, setRequiredSelects] = useState([]);
    const [country, setCountry] = useState('');
    const [fieldsByState, setFieldsByState] = useState({});
    const [formInputs, setFormInputs] = useState({});
    const [shouldDisplayWarning, setShouldDisplayWarning] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [files, setFiles] = useState([]);
    const [extraFields, setExtraFields] = useState({});
    const countryList = getCountriesInCurrentLanguage(language);
    const monthsList = getMonthInCurrentLanguage(language);
    const days = createDaysObject();
    const year = new Date().getFullYear();
    const nextYear = year + 1;
    const KEY = 0;
    const VALUE = 1;
    const filteredFieldWithWebformFile = Object.entries(webform?.schema ?? [])?.filter(
        (value) => value[1][TYPE] === WEBFORM_DOCUMENT_FILE
    );
    const fieldWithFileName = filteredFieldWithWebformFile?.length > 0 ? filteredFieldWithWebformFile[0][0] : '';
    const multipleFiles =
        filteredFieldWithWebformFile?.length > 0 && filteredFieldWithWebformFile[0][1].hasOwnProperty(MULTIPLE)
            ? filteredFieldWithWebformFile[0][1][MULTIPLE]
            : 1;

    useEffect(() => {
        setFieldsByState((fieldsByState) => ({ ...fieldsByState, land: country }));
        if (country.length > 0) {
            setFormInputs({ ...formInputs, land: { ...formInputs[LAND], value: country } });
        } else if (formInputs.hasOwnProperty(LAND)) {
            setFormInputs({ ...formInputs, land: { ...formInputs[LAND], value: country } });
        }
    }, [country]);

    useEffect(() => {
        if (Object.values(checkboxes)[0]?.length > 0) {
            setFormInputs({ ...formInputs, checkboxes: { ...formInputs[CHECKBOXES], value: true } });
        } else if (formInputs.hasOwnProperty(CHECKBOXES)) {
            setFormInputs({ ...formInputs, checkboxes: { ...formInputs[CHECKBOXES], value: false } });
        }
    }, [checkboxes]);

    useEffect(() => {
        const requiredInputs = Object.values(formInputs)?.filter(
            (field: any) => field?.type === INPUT && field?.required
        );
        const checkboxFields = Object.values(formInputs)?.filter((field: any) => field?.type === CHECKBOX);
        const requiredSelects = Object.values(formInputs)?.filter(
            (field: any) => field?.type === SELECT && field?.required
        );
        if (
            Object.values(radios)?.length >= requiredRadios.length &&
            (requiredInputs?.length === 0 || requiredInputs?.every((input: any) => input?.value?.length > 0)) &&
            (checkboxFields?.length === 0 || checkboxFields?.every((checkbox: any) => checkbox?.value)) &&
            (requiredSelects?.length === 0 || requiredSelects?.every((select: any) => select?.value?.length > 0))
        ) {
            if (shouldDisplayWarning) {
                setShouldDisplayWarning(false);
            }
        } else {
            if (!shouldDisplayWarning) {
                setShouldDisplayWarning(true);
            }
        }

        const datesInputs = Object.keys(formInputs).filter((elem) => formInputs[elem]?.value?.hasOwnProperty(YEAR));
        if (datesInputs.length) {
            datesInputs.forEach((dateElem) => {
                setExtraFields((fields) => {
                    const value = formInputs[dateElem]?.value;
                    const selectedDay = value?.day;
                    const selectedMonth = value?.month;
                    const selectedYear = value?.year;

                    if (selectedDay?.length && selectedMonth?.length && selectedYear?.length) {
                        return { ...fields, [dateElem]: `${selectedYear}-${selectedMonth}-${selectedDay}` };
                    }
                    return { ...fields, [dateElem]: '' };
                });
            });
        }
    }, [formInputs, radios]);

    const getWebform = async (): Promise<void> => {
        //TODO when be fix problem with languages, change here and pass 'de' instead of 'en'
        const { schema, submission_link, link, wid, settings } = await useFetchWebform(EN, webformid);
        setIsLoading(false);
        setWebform({ schema, submission_link, link, wid, settings });
    };

    const displayTextInputField = (type: string, index: number, item: any): ReactNode => {
        if (!formInputs.hasOwnProperty(item[KEY])) {
            setFormInputs({
                ...formInputs,
                [item[KEY]]: {
                    value: '',
                    required: item[VALUE].hasOwnProperty(REQUIRED) ? item[VALUE][REQUIRED] : false,
                    type: INPUT,
                },
            });
        }
        return (
            <InputSectionWrapper key={`${item[KEY]}${index}`}>
                <InputSectionHeadline display={item[VALUE][TITLE_DISPLAY]}>
                    {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                    {item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED] === true && <span>*</span>}
                </InputSectionHeadline>
                <TextInput
                    width={'69%'}
                    name={item[KEY]}
                    type={type}
                    onChange={(e) =>
                        setFormInputs({
                            ...formInputs,
                            [item[KEY]]: { ...formInputs[item[KEY]], value: e.target.value },
                        })
                    }
                    placeholder={
                        item[VALUE].hasOwnProperty(REQUIRED) &&
                        item[VALUE].hasOwnProperty(PLACEHOLDER) &&
                        item[VALUE][REQUIRED] === true
                            ? `${item[VALUE][PLACEHOLDER]}*`
                            : item[VALUE][PLACEHOLDER]
                    }
                    required={item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED]}
                    onInvalid={(e: ChangeEvent<HTMLInputElement>) =>
                        item[VALUE].hasOwnProperty(REQUIRED_ERROR) &&
                        item[VALUE][REQUIRED_ERROR] &&
                        e.target.setCustomValidity(item[VALUE][REQUIRED_ERROR])
                    }
                    onInput={(e: ChangeEvent<HTMLInputElement>) => e.target.setCustomValidity('')}
                />
                {item[VALUE].hasOwnProperty(DESCRIPTION) && (
                    <Description
                        key={index}
                        dangerouslySetInnerHTML={{ __html: item[VALUE][DESCRIPTION] }}
                        display={item[VALUE][DESCRIPTION_DISPLAY]}
                    ></Description>
                )}
            </InputSectionWrapper>
        );
    };

    const displayRadioInput = (item: any, key: any): ReactNode => {
        const radioName = item[KEY];
        if (
            item[VALUE].hasOwnProperty(REQUIRED) &&
            item[VALUE][REQUIRED] === true &&
            !requiredRadios.includes(radioName)
        ) {
            setRequiredRadios((requiredRadios) => [...requiredRadios, radioName]);
        }

        return (
            <FormSectionWrapper key={key}>
                <FormSectionTitle display={item[VALUE][TITLE_DISPLAY]}>
                    {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                    {item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED] === true && <span>*</span>}
                </FormSectionTitle>
                <RadioContainer display={item[VALUE][OPTIONS_DISPLAY]}>
                    {item[VALUE].hasOwnProperty(OPTIONS) &&
                        Object.entries(item[VALUE][OPTIONS])?.map((item: any, index: number) => {
                            return (
                                <RadioBoxContainerMargin key={`${item[KEY]}${index}`}>
                                    <RadioLabel htmlFor={radioName}>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: item[VALUE],
                                            }}
                                        ></div>
                                    </RadioLabel>
                                    <RadioInput
                                        state={radios}
                                        setState={setRadios}
                                        name={radioName}
                                        value={item[KEY]}
                                        required={item[VALUE][REQUIRED]}
                                        requiredErrorMessage={item[VALUE][REQUIRED_ERROR]}
                                    />
                                </RadioBoxContainerMargin>
                            );
                        })}
                </RadioContainer>
            </FormSectionWrapper>
        );
    };

    const displayOptions = (dataArray: any): ReactNode =>
        dataArray?.map((data: any) => (
            <option key={data.value} value={data.value}>
                {data.name}
            </option>
        ));

    const range = (start: number, stop: number): any =>
        Array(Math.ceil(stop - start))
            .fill(start)
            .map((x, y) => x + y);

    const displayData = (datePart: string, data: any): ReactNode => {
        switch (datePart) {
            case DAY:
                return displayOptions(days);
            case MONTH:
                return displayOptions(monthsList);
            case YEAR:
                let years = [];

                if (data.hasOwnProperty(DATE_YEAR_RANGE) && data[DATE_YEAR_RANGE]) {
                    const selectedDate = data[DATE_YEAR_RANGE].split(':');
                    if (selectedDate[0] && selectedDate[1]) {
                        years = range(Number(selectedDate[0]), Number(selectedDate[1]) + 1)?.map((e) => ({
                            name: e,
                            value: e,
                        }));
                    } else if (selectedDate[0]) {
                        years = [{ name: Number(selectedDate[0]), value: Number(selectedDate[0]) }];
                    }
                } else {
                    years = [
                        { name: year, value: year },
                        { name: nextYear, value: nextYear },
                    ];
                }
                return displayOptions(years);
            default:
                return null;
        }
    };

    const displayDatelist = (item: any, index: any): ReactNode => {
        if (
            item[VALUE].hasOwnProperty(REQUIRED) &&
            item[VALUE][REQUIRED] === true &&
            !requiredSelects.includes(item[KEY])
        ) {
            setRequiredSelects((requiredSelects) => [...requiredSelects, item[KEY]]);
        }
        if (!formInputs.hasOwnProperty(item[KEY])) {
            setFormInputs({
                ...formInputs,
                [item[KEY]]: {
                    value: {
                        day: '',
                        month: '',
                        year: '',
                    },
                    required: item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED],
                    type: SELECT,
                },
            });
        }

        const convertDatePart = (datePart: string, value: string) =>
            (datePart === DAY || datePart === MONTH) && value.length === 1 ? `0${value}` : value;

        const renderParts = (): ReactNode => {
            if (item[VALUE].hasOwnProperty(DATE_PART_ORDER)) {
                return item[VALUE][DATE_PART_ORDER].map((datePart: string) => (
                    <CommonSelectMargin
                        key={datePart}
                        width={'150px'}
                        name={item[KEY]}
                        onChange={(e) =>
                            setFormInputs({
                                ...formInputs,
                                [item[KEY]]: {
                                    ...formInputs[item[KEY]],
                                    value: {
                                        ...formInputs[item[KEY]]?.value,
                                        [datePart]: convertDatePart(datePart, e.target.value),
                                    },
                                },
                            })
                        }
                        onInvalid={(e: ChangeEvent<HTMLSelectElement>) =>
                            item[VALUE].hasOwnProperty(REQUIRED_ERROR) &&
                            item[VALUE][REQUIRED_ERROR] &&
                            e.target.setCustomValidity(item[VALUE][REQUIRED_ERROR])
                        }
                        onInput={(e: ChangeEvent<HTMLSelectElement>) => e.target.setCustomValidity('')}
                        required={item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED]}
                    >
                        <option value={''}>{t(datePart)}</option>
                        {displayData(datePart, item[VALUE])}
                    </CommonSelectMargin>
                ));
            }

            return null;
        };

        return (
            <FormSectionWrapper key={`${item[KEY]}${index}`}>
                <FormSectionTitle>
                    {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                    {item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED] && <span>*</span>}
                </FormSectionTitle>
                {renderParts()}
            </FormSectionWrapper>
        );
    };

    const displayFunctionOptions = (item: any): ReactNode =>
        item[VALUE].hasOwnProperty(OPTIONS) &&
        Object.entries(item[VALUE][OPTIONS]).map((option) => (
            <option value={option[KEY]} key={option[KEY]}>
                {option[VALUE]}
            </option>
        ));

    const displaySelect = (item: any, index: number): ReactNode => {
        if (
            item[VALUE].hasOwnProperty(REQUIRED) &&
            item[VALUE][REQUIRED] === true &&
            !requiredSelects.includes(item[KEY])
        ) {
            setRequiredSelects((requiredSelects) => [...requiredSelects, item[KEY]]);
        }
        if (!formInputs.hasOwnProperty(item[KEY])) {
            setFormInputs({
                ...formInputs,
                [item[KEY]]: {
                    value: '',
                    required: item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED],
                    type: SELECT,
                },
            });
        }

        if (item[VALUE].hasOwnProperty(OPTIONS) && item[VALUE][OPTIONS] === COUNTRY_NAMES) {
            return (
                <FormSectionWrapper key={`${item[KEY]}${index}`}>
                    <FormSectionTitle>
                        {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                        {item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED] && <span>*</span>}
                    </FormSectionTitle>
                    <SelectWithSearch
                        setState={setCountry}
                        currentValue={country}
                        selectData={countryList}
                        defaultValue={' - '}
                        width={'13rem'}
                        isRequired={item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED]}
                        requiredErrorMessage={item[VALUE][REQUIRED_ERROR]}
                    />
                </FormSectionWrapper>
            );
        } else {
            return (
                <FormSectionWrapper key={`${item[KEY]}${index}`}>
                    <FormSectionTitle>
                        {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                        {item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED] && <span>*</span>}
                    </FormSectionTitle>
                    <CommonSelect
                        width={'350px'}
                        name={item[KEY]}
                        onChange={(e) =>
                            setFormInputs({
                                ...formInputs,
                                [item[KEY]]: {
                                    ...formInputs[item[KEY]],
                                    value: e.target.value,
                                },
                            })
                        }
                        onInvalid={(e: ChangeEvent<HTMLSelectElement>) =>
                            item[VALUE].hasOwnProperty(REQUIRED_ERROR) &&
                            item[VALUE][REQUIRED_ERROR] &&
                            e.target.setCustomValidity(item[VALUE][REQUIRED_ERROR])
                        }
                        onInput={(e: ChangeEvent<HTMLSelectElement>) => e.target.setCustomValidity('')}
                        required={item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED]}
                    >
                        <option value={''}>{t('select')}</option>
                        {displayFunctionOptions(item)}
                    </CommonSelect>
                </FormSectionWrapper>
            );
        }
    };

    const displayCorrectField = (item: any, key: number): ReactNode => {
        switch (item[VALUE][TYPE]) {
            case FIELDSET:
                if (item[VALUE].hasOwnProperty(DESCRIPTION)) {
                    return <Text key={key} dangerouslySetInnerHTML={{ __html: item[VALUE][DESCRIPTION] }}></Text>;
                } else {
                    return (
                        <FormSectionWrapper key={key} deleteMarginBottom={deleteMarginBottom}>
                            {item[VALUE].hasOwnProperty(TITLE) && (
                                <FormSectionTitle
                                    titleType={item[VALUE][TITLE_TYPE]}
                                    display={item[VALUE][TITLE_DISPLAY]}
                                >
                                    {item[VALUE][TITLE]}
                                </FormSectionTitle>
                            )}
                            {Object?.entries(item[VALUE])?.map((item: any, index: number) => {
                                if (item.length > 1) {
                                    switch (item[VALUE][TYPE]) {
                                        case TEXTFIELD:
                                        case TELEPHONE:
                                            return displayTextInputField(TEXT, index, item);
                                        case EMAIL:
                                            return displayTextInputField(EMAIL, index, item);
                                        case TEXTAREA:
                                            return (
                                                <TextAreaWithGrippie
                                                    key={`${item[KEY]}${index}`}
                                                    name={item[KEY]}
                                                    required={item[VALUE][REQUIRED]}
                                                    requiredErrorMessage={item[VALUE][REQUIRED_ERROR]}
                                                />
                                            );
                                        case CHECKBOXES:
                                            const checkboxName = item[KEY];
                                            if (!formInputs.hasOwnProperty(CHECKBOXES)) {
                                                setFormInputs({
                                                    ...formInputs,
                                                    checkboxes: { value: false, required: false, type: CHECKBOX },
                                                });
                                            }
                                            if (
                                                item[VALUE].hasOwnProperty(REQUIRED) &&
                                                item[VALUE][REQUIRED] === true &&
                                                !requiredCheckboxes.includes(checkboxName)
                                            ) {
                                                setRequiredCheckboxes((requiredCheckboxes) => [
                                                    ...requiredCheckboxes,
                                                    checkboxName,
                                                ]);
                                            }
                                            return (
                                                <FormSectionWrapper key={key}>
                                                    {item[VALUE].hasOwnProperty(OPTIONS) &&
                                                        Object.entries(item[VALUE][OPTIONS])?.map(
                                                            (item: any, index: number) => {
                                                                return (
                                                                    <RadioBoxContainer key={`${item[KEY]}${index}`}>
                                                                        <RadioLabel htmlFor={checkboxName}>
                                                                            <div
                                                                                dangerouslySetInnerHTML={{
                                                                                    __html: item[VALUE],
                                                                                }}
                                                                            ></div>{' '}
                                                                        </RadioLabel>
                                                                        <CheckboxInput
                                                                            setState={setCheckboxes}
                                                                            state={checkboxes}
                                                                            name={checkboxName}
                                                                            value={item[VALUE]}
                                                                            required={item[VALUE][REQUIRED]}
                                                                            requiredErrorMessage={
                                                                                item[VALUE][REQUIRED_ERROR]
                                                                            }
                                                                        />
                                                                    </RadioBoxContainer>
                                                                );
                                                            }
                                                        )}
                                                </FormSectionWrapper>
                                            );
                                        case RADIOS:
                                            return displayRadioInput(item, `${item[0] ?? item}${key}`);
                                        case FIELDSET:
                                            if (item[VALUE].hasOwnProperty(TITLE)) {
                                                return (
                                                    <FormSectionWrapper
                                                        key={item[VALUE][TITLE]}
                                                        deleteMarginBottom={deleteMarginBottom}
                                                    >
                                                        {shouldDisplayWarning && (
                                                            <>
                                                                <FormSectionTitle
                                                                    titleType={item[VALUE][TITLE_TYPE]}
                                                                    display={item[VALUE][TITLE_DISPLAY]}
                                                                >
                                                                    {item[VALUE][TITLE]}
                                                                </FormSectionTitle>
                                                                <Text
                                                                    key={key}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: item[VALUE][DESCRIPTION],
                                                                    }}
                                                                ></Text>
                                                            </>
                                                        )}
                                                    </FormSectionWrapper>
                                                );
                                            }
                                            break;
                                        case SELECT:
                                            return displaySelect(item, index);
                                        case DATELIST:
                                            return displayDatelist(item, index);
                                        default:
                                            return null;
                                    }
                                }
                            })}
                        </FormSectionWrapper>
                    );
                }
            case WEBFORM_DOCUMENT_FILE:
                return (
                    <FileInputWrapper key={key}>
                        <FormSectionTitle display={item[VALUE][TITLE_DISPLAY]}>
                            {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                            {item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED] === true && <span>*</span>}
                        </FormSectionTitle>
                        <FileInput
                            files={files}
                            setFiles={setFiles}
                            width={'340px'}
                            required={item[VALUE][REQUIRED] === true}
                            requiredErrorMessage={item[VALUE][REQUIRED_ERROR]}
                        />
                    </FileInputWrapper>
                );
            case TEXTAREA:
                return (
                    <TextAreaWrapper key={key}>
                        <FormSectionTitle display={item[VALUE][TITLE_DISPLAY]}>
                            {item[VALUE].hasOwnProperty(TITLE) && item[VALUE][TITLE]}
                            {item[VALUE].hasOwnProperty(REQUIRED) && item[VALUE][REQUIRED] === true && <span>*</span>}
                        </FormSectionTitle>
                        <TextAreaWithGrippie
                            key={key}
                            name={item[KEY]}
                            required={item[VALUE][REQUIRED]}
                            requiredErrorMessage={item[VALUE][REQUIRED_ERROR]}
                        />
                    </TextAreaWrapper>
                );
            case RADIOS:
                return displayRadioInput(item, key);
            case SELECT:
                return displaySelect(item, 0);
            case DATELIST:
                return displayDatelist(item, 0);
            case TEXTFIELD:
            case TELEPHONE:
                return displayTextInputField(TEXT, 0, item);
            case EMAIL:
                return displayTextInputField(EMAIL, 0, item);
            default:
                return null;
        }
    };

    useEffect(() => {
        setIsLoading(true);
        getWebform();
    }, []);

    return (
        <>
            {isLoading ? <Loader /> : ''}
            {webform.hasOwnProperty('schema') ? (
                <StyledWebform
                    isParagraphType={isParagraphType}
                    id={webformid}
                    style={{
                        ...(language === EN_US && { backgroundColor: greyBackground, padding: 25 }),
                        ...style,
                    }}
                    onSubmit={(e) => {
                        setIsLoading(true);
                        useHandleSubmitWebform(
                            e,
                            webformid,
                            language,
                            webform?.settings?.confirmation_title !== ''
                                ? webform.settings?.confirmation_title
                                : confirmationTitle,
                            requiredRadios,
                            requiredCheckboxes,
                            requiredSelects,
                            checkboxes,
                            fieldsByState,
                            webform?.settings?.confirmation_url ?? null,
                            files,
                            fieldWithFileName,
                            multipleFiles,
                            webform?.settings?.confirmation_message ?? null,
                            extraFields
                        ).then(() => setIsLoading(false));
                    }}
                >
                    {webform.hasOwnProperty('schema') &&
                        Object.entries(webform.schema)?.map((item: any, index: number) =>
                            displayCorrectField(item, index)
                        )}
                    <input type="hidden" name="webform_id" value={webform?.wid} />
                    <SendButtonGreenGradient isWebform={true} width="20rem" type="submit">
                        {t('webformSubmitButton')}
                    </SendButtonGreenGradient>
                </StyledWebform>
            ) : null}
        </>
    );
};

export default WebForm;
