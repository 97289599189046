import React, { FC, useState, ChangeEvent, ReactNode } from 'react';
import { FileInputWrapper, FileInputParagraph } from '../../../styles/Common';
import { useTranslation } from 'gatsby-plugin-react-i18next';

interface FileInputInterface {
    files: Array<any>;
    setFiles: Function;
    width?: string;
    required?: boolean;
    requiredErrorMessage?: string;
}

const FileInput: FC<FileInputInterface> = ({ files, setFiles, width, required, requiredErrorMessage }) => {
    const [filesSize, setFilesSize] = useState(0);
    const { t } = useTranslation();

    const maxFilesSize = 10 * 1024 * 1024;
    const acceptableFileTypes = [
        'application/pdf',
        'application/zip',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.oasis.opendocument.text',
    ];

    const addFile = (e: any): void => {
        if (e.target && e.target.files[0]) {
            if (!files?.map((file) => file.name).includes(e.target.files[0].name)) {
                if (acceptableFileTypes.includes(e.target.files[0].type)) {
                    if (filesSize + e.target.files[0].size < maxFilesSize) {
                        setFiles((files: any) => [...files, e.target.files[0]]);
                        setFilesSize((fileSize) => e.target.files[0].size + fileSize);
                    } else {
                        alert(t('filesSizeIsTooBig'));
                    }
                } else {
                    alert(t('availableInputFormats'));
                }
            } else {
                alert(`${t('theFile')} ${e.target.files[0].name} ${t('alreadySelected')}.`);
            }
        }
    };

    const deleteFile = (name: string): void => {
        let fileIndexToDelete = files.map((file) => file.name).indexOf(name);
        setFiles((files: any) => files.slice(0, fileIndexToDelete).concat(files.slice(fileIndexToDelete + 1)));
    };

    const displayFilesName = (): ReactNode =>
        files?.map((file) => (
            <FileInputParagraph key={file.name}>
                <span onClick={() => deleteFile(file.name)}>Remove</span> {file.name}
            </FileInputParagraph>
        ));

    return (
        <>
            <FileInputWrapper
                type="file"
                onChange={(e) => addFile(e)}
                disabled={files?.length === 3}
                width={width}
                required={required}
                onInvalid={(e: ChangeEvent<HTMLInputElement>) =>
                    requiredErrorMessage && e.target.setCustomValidity(requiredErrorMessage)
                }
                onInput={(e: ChangeEvent<HTMLInputElement>) => e.target.setCustomValidity('')}
            />
            {displayFilesName()}
            <FileInputParagraph information={true}>{t('inputFileRule')}</FileInputParagraph>
        </>
    );
};

export default FileInput;
