import styled, { css } from 'styled-components';
import { TWO_COLUMNDS } from '../../constants/commonConstants';
import { CommonSelect } from '../../styles/Common';
import { deviceMax } from '../../styles/MediaQuery';
import { paragraphMargin, paragraphWidthRem } from '../../styles/Variables';
import { RadioBoxContainer } from '../ContactUk/ContactUkStyle';

interface WebformProps {
    readonly isParagraphType?: boolean;
}

export const StyledWebform = styled.form<WebformProps>`
    ${({ isParagraphType }) =>
        isParagraphType &&
        css`
            margin-top: ${paragraphMargin};
            max-width: ${paragraphWidthRem};
            width: 100%;

            @media ${deviceMax.tablet} {
                max-width: 90%;
                width: 90%;
            }
        `};
`;

export const CommonSelectMargin = styled(CommonSelect)`
    margin-right: 0.5rem;
`;

interface RadioContainerProps {
    readonly display?: string;
}

export const RadioContainer = styled.div<RadioContainerProps>`
    display: flex;
    flex-direction: ${({ display }) => (display === TWO_COLUMNDS ? 'row' : 'column')};
`;

export const RadioBoxContainerMargin = styled(RadioBoxContainer)`
    margin-right: 2rem;
`;
