import styled from 'styled-components';
import { CommonInput } from '../../../styles/Common';
import { blue, errorBackground } from '../../../styles/Variables';
import dateError from '../../../static/images/message-24-error.png';

export const Title = styled.h1`
    color: ${blue};
    font-size: 40px;
    font-weight: 700;
    letter-spacing: 0.025em;
    line-height: 2.75rem;
    margin-bottom: 17px;
    margin-top: 0;
`;

export const TextInput = styled(CommonInput)`
    box-sizing: border-box;
`;

export const FormSectionHeadline = styled.h3`
    text-transform: capitalize;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
    width: 100%;
    margin: 0;
    span {
        color: #f00;
        font-weight: 700;
    }
`;

export const InputWrapper = styled.div`
    margin-bottom: 15px;
    margin-right: 10px;
    width: calc(50% - 10px);
`;

interface FormSectionWrapperInterface {
    readonly deleteMarginBottom?: boolean;
}

export const FormSectionWrapper = styled.div<FormSectionWrapperInterface>`
    margin: 0;
    padding: 0;
    margin-bottom: ${(props) => (props.deleteMarginBottom ? '0' : '2.5rem')};
    display: flex;
    flex-wrap: wrap;
`;

export const RadioSectionWrapper = styled.div`
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
`;

export const SelectWrapper = styled.div`
    width: 100%;
    margin-bottom: 15px;
`;

export const SelectGroupWrapper = styled.div`
    width: 100%;
    margin-bottom: 15px;
    display: flex;
    flex-wrap: wrap;
`;

export const SingleSelectWrapper = styled.div`
    margin-right: 5px;
`;

export const FileInputWrapper = styled.div`
    width: 100%;
    margin-bottom: 15px;
    margin-top: 40px;
`;

export const TextAreaWrapper = styled.div`
    width: 574px;
    margin-bottom: 15px;
    max-width: 100%;
`;

export const RadioLabel = styled.label`
    font-size: 0.875rem;
    line-height: 0.875rem;
    padding-left: 1rem;
    width: calc(100% - 1rem);

    div {
        font-size: 0.875rem;
        line-height: 0.875rem;
    }

    a {
        text-decoration: none;
        color: ${blue};
        margin: 0 2px;
    }

    * {
        font-family: Arial, Helvetica, sans-serif;
    }
`;

export const RadioBoxContainer = styled.div`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1rem;
    margin-top: 1rem;
`;

export const RadioInlineWrapper = styled.div`
    width: 120px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
`;

export const Note = styled.div`
    margin-bottom: 2rem;
    * {
        font-family: Arial, Helvetica, sans-serif;
        font-size: 0.875rem;
    }
`;

export const SendButtonWrapper = styled.div`
    width: calc(35% + 2.25rem);
    display: flex;
    align-items: center;
    * {
        font-family: Arial, Helvetica, sans-serif;
    }
`;

export const DateError = styled.div`
    background: url(${dateError});
    background-color: ${errorBackground};
    margin: 6px 0;
    padding: 10px 10px 10px 50px;
    background-position: 8px 8px;
    background-repeat: no-repeat;
    border: 1px solid red;
    font-family: Arial, Helvetica, sans-serif;
`;
