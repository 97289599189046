import { DE, EN } from '../constants/languageCode';
import monthsDe from '../JSON/MonthList/months-de.json';
import monthsEn from '../JSON/MonthList/months-en.json';

export const getMonthInCurrentLanguage = (language: string) => {
    switch (language) {
        case DE:
            return monthsDe;
        case EN:
            return monthsEn;
        default:
            return monthsDe;
    }
};
