import React from 'react';
import { Container, Spinner } from './LoaderStyle';

const Loader = () => {
    return (
        <Container>
            <Spinner className="lds-roller">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </Spinner>
        </Container>
    );
};

export default Loader;
